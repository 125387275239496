import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';

import { TitleColour } from '../../utils/variables';
import '../Form/fields/checkbox.css';

import loadComponents from '../Loadable';

const H2 = loadComponents('h2');

export default function CheckoutTotals({ total, currency }) {
  return (
    <div style={{ marginTop: `2.8125rem` }}>
      <H2>Total</H2>
      <ul>
        <li
          style={{
            ...liStyle,
          }}
        >
          <span>Grand Total</span>
          <strong style={strongStyle}>
            {getSymbolFromCurrency(currency)}
            {total}
          </strong>
        </li>
      </ul>
    </div>
  );
}

const liStyle = {
  display: `flex`,
  justifyContent: `space-between`,
  alignItems: `center`,
};

const strongStyle = {
  fontSize: `1.25rem`,
  color: TitleColour,
};
